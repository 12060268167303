import {type UnwrapRef} from 'vue';
import {ref, watchEffect} from '#imports';

export const computedWritable = <T>(getValue: () => T) => {
  const value = ref(getValue());
  watchEffect(() => {
    value.value = getValue() as UnwrapRef<T>;
  });
  return value;
};
